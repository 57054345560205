import styled from "styled-components";
import { mediaQueryW } from "styles/utils/mixins.styles";
import { PeriodContainer } from "components/_global/Period/Period.styles";

export const FooterContainer = styled.footer`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-top: 26rem;

  ${mediaQueryW.max.d`
    margin-top: 23rem;
  `}

  ${mediaQueryW.max.md`
    margin-top: 21rem;
  `}

  ${mediaQueryW.max.m`
    margin-top: 19rem;
  `}

  ${mediaQueryW.max.s`
    margin-top: 16rem;
  `}

  ${mediaQueryW.max.xs`
    margin-top: 13rem;
    align-items: center;
  `}

  ${mediaQueryW.max.xxs`
    margin-top: 10rem;
  `}
`;

export const Copyright = styled.small`
  display: flex;
  align-items: center;

  ${PeriodContainer} {
    margin-right: 2rem;

    ${mediaQueryW.max.s`
      margin-right: 1rem;
    `}
  }
`;
