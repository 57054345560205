import React from "react";

import HomeHero from "components/home/HomeHero/HomeHero.component";
import HomeGrid from "components/home/HomeGrid/HomeGrid.component";

import { HomePageContainer } from "./HomePage.styles";

const HomePage = () => (
  <HomePageContainer>
    <HomeHero />
    <HomeGrid />
  </HomePageContainer>
);

export default HomePage;
