import { INITIAL_STATE } from "./cursor.data";
import CursorActionTypes from "./cursor.types";

const cursorReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CursorActionTypes.UPDATE_CURSOR_TYPE:
      return {
        ...state,
        type: action.payload,
      };
    default:
      return state;
  }
};

export default cursorReducer;
