import styled, { css } from "styled-components";

const HeadlineStyles = css`
  & + & {
    margin-top: 1.3em;
  }
`;

export const HeadlineContainer = styled.h1`
  ${HeadlineStyles}
`;
