export const checkIfUrlIsExternal = (url) => {
  const regex = /(https?:\/\/)?[\w\-~]+(\.[\w\-~]+)+(\/[\w\-~@:%]*)*(#[\w-]*)?(\?[^\s]*)?/gi;

  return !!url.match(regex);
};

export const getCurrentIfRef = (el) =>
  el && el.hasOwnProperty("current") ? el.current : el;

export const disableCursor = (element) => {
  element.style.cursor = "none";
};
