import styled from "styled-components";
import { mediaQueryW } from "styles/utils/mixins.styles";
import { LabelStyles } from "styles/utils/typography.styles";

export const LabelContainer = styled.span`
  ${LabelStyles}
  display: block;
  margin-bottom: 1.75em;

  ${mediaQueryW.max.m`
    margin-bottom: 1.5em;
  `}

  ${mediaQueryW.max.xs`
    margin-bottom: 1.25em;
  `}
`;
