import React from "react";
import { withTheme } from "styled-components";

import UnderlineHover from "components/_global/UnderlineHover/UnderlineHover.component";
import CustomLink from "components/_global/CustomLink/CustomLink.component";

import { ListContainer, ListItemContainer } from "./HomeGridList.styles";

const HomeGridList = ({ label, list, theme }) => (
  <ListContainer>
    {list.map(({ text, url }, i) => (
      <ListItemContainer key={i}>
        {url ? (
          <UnderlineHover color="transparent" hoverColor={theme.baseColor}>
            <CustomLink url={url} text={text} />
          </UnderlineHover>
        ) : (
          text
        )}
      </ListItemContainer>
    ))}
  </ListContainer>
);

export default withTheme(HomeGridList);
