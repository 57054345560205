import React from "react";
import styled, { css } from "styled-components";
import { animated } from "react-spring";

export const UnderlineHoverContainer = styled(
  ({ styleOptions, style, customStyles, ...props }) => (
    <animated.span style={style} {...props} />
  )
)`
  line-height: initial;

  ${({
    theme,
    styleOptions: { color, hoverColor, bottomPosition },
    customStyles,
  }) => css`
    background-repeat: no-repeat;
    background-image: linear-gradient(
        to left,
        ${hoverColor || "transparent"},
        ${hoverColor || "transparent"}
      ),
      linear-gradient(
        to left,
        ${color || theme.primaryColor},
        ${color || theme.primaryColor}
      );
    background-position: right 0 bottom ${bottomPosition},
      left 0 bottom ${bottomPosition};

    &.isHovered {
      background-position: left 0 bottom ${bottomPosition},
        right 0 bottom ${bottomPosition};
    }

    ${customStyles && customStyles}
  `}
`;
