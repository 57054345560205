import React, { useEffect, useRef } from "react";
import useHiddenClass from "hooks/useHiddenClass.hook";
import gsap from "gsap";

import TextWithCustomPeriods from "components/_global/TextWithCustomPeriods/TextWithCustomPeriods.component";
import HeadlineLink from "components/_global/HeadlineLink/HeadlineLink.component";

import { HeadlineContainer } from "./Headline.styles";

const Headline = ({ blocks }) => {
  const hiddenClass = useHiddenClass();
  const headlineRef = useRef();

  useEffect(() => {
    gsap.from(headlineRef.current, {
      duration: 0.6,
      y: "30%",
      opacity: 0,
      ease: "expo.inout",
      scrollTrigger: {
        trigger: headlineRef.current,
        start: "-10% bottom",
        toggleActions: "play none none reverse",
      },
    });
  }, []);

  return (
    <HeadlineContainer ref={headlineRef} className={hiddenClass}>
      {blocks.map((item, index) => {
        switch (item.type.toLowerCase()) {
          case "text":
            return <TextWithCustomPeriods key={index} text={item.text} />;
          case "link":
            return <HeadlineLink key={index} url={item.url} text={item.text} />;
          default:
            return null;
        }
      })}
    </HeadlineContainer>
  );
};

export default Headline;
