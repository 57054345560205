import styled from "styled-components";
import { mediaQueryW } from "styles/utils/mixins.styles";

export const ColorThemeTogglerContainer = styled.div`
  display: inline-flex;
  align-items: center;
`;

export const Text = styled.small`
  margin-right: 1em;

  ${mediaQueryW.max.s`
    margin-right: .75em
  `}

  ${mediaQueryW.max.xs`
    display: none;
  `}
`;
