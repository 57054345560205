export const HERO_DATA = [
  [
    {
      type: 'text',
      text: 'Hello!*I’m Mélissa, a creative developer based in Brooklyn.',
    },
  ],
  [
    {
      type: 'text',
      text: 'Some of my clients include ',
    },
    {
      type: 'link',
      text: 'Carnegie Hall',
      url: 'https://ch.clausse.work/modules.php',
    },
    {
      type: 'text',
      text: ', the ',
    },
    {
      type: 'link',
      text: 'American Museum of Natural History',
      url: 'https://amnh.clausse.work/home.php',
    },
    {
      type: 'text',
      text: ' <em>&</em> ',
    },
    {
      type: 'link',
      text: 'Verse',
      url: 'https://www.verse.com',
    },
    {
      type: 'text',
      text: '.',
    },
  ],
];
