import React, { useEffect, useRef, useState, useCallback, memo } from "react";
import gsap from "gsap";
import useEventListener from "hooks/useEventListener.hook";
import { useToggleCursorPointer } from "hooks/useCursor.hook";
import { disableCursor } from "utils/helpers/global.helpers";
import CustomCursorIcon from "components/_global/CustomCursorIcon/CustomCursorIcon.component";

import { Cursor, CursorInner } from "./CustomCursor.styles";

const pointerTargets = ["a", "button"];

const CustomCursor = ({ delay }) => {
  const [xy, setXY] = useState({ x: 0, y: 0 });
  const [isActive, setIsActive] = useState(undefined);
  const [isDown, setIsDown] = useState(undefined);
  const cursorRef = useRef(undefined);
  const pointerTargetsRef = useRef(undefined);

  const setCursorPosition = useCallback(
    (x, y) => {
      const duration = isActive ? 0.125 : 0;
      gsap.to(cursorRef.current, duration, { x, y });
    },
    [isActive]
  );

  const handleMouseUp = () => isDown && setIsDown(false);
  const handleMouseDown = () => !isDown && setIsDown(true);
  const handleMouseEnter = () => !isActive && setIsActive(true);
  const handleMouseLeave = () => isActive && setIsActive(false);

  const handleMouseMove = useCallback(
    ({ clientX: x, clientY: y }) => setXY({ x, y }),
    []
  );

  useEffect(() => {
    const { x, y } = xy;
    setCursorPosition(x, y);
  }, [xy, setCursorPosition]);

  useEffect(() => {
    disableCursor(document.body);
    pointerTargetsRef.current = document.querySelectorAll(pointerTargets);
  }, []);

  useEffect(() => {
    if (isActive === undefined) return;

    gsap.to(cursorRef.current, 0.25, {
      opacity: `${isActive ? 1 : 0}`,
      scale: `${isActive ? 1 : 0}`,
    });
  }, [isActive]);

  useEffect(() => {
    if (!isActive) return;

    gsap.to(cursorRef.current, 0.25, {
      scale: `${isDown ? 0.8 : 1}`,
    });
  }, [isDown, isActive]);

  useEventListener(document, "mousemove", handleMouseMove);
  useEventListener(document, "mousemove", handleMouseEnter);
  useEventListener(document, "mouseup", handleMouseUp);
  useEventListener(document, "mousedown", handleMouseDown);
  useEventListener(document.body, "mouseleave", handleMouseLeave);
  useToggleCursorPointer(pointerTargetsRef);

  return (
    <>
      <Cursor ref={cursorRef}>
        <CursorInner>
          <CustomCursorIcon />
        </CursorInner>
      </Cursor>
    </>
  );
};

export default memo(CustomCursor);
