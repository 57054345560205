import React from "react";
import { withTheme } from "styled-components";
import { useColorTheme } from "hooks/useThemes.hook";
import ColorThemeOptions from "themes/color/colorTheme.options";
import { ReactComponent as sunIcon } from "assets/icons/sun.svg";
import { ReactComponent as moonIcon } from "assets/icons/moon.svg";

import Toggler from "components/_global/Toggler/Toggler.component";

import { ColorThemeTogglerContainer, Text } from "./ColorThemeToggler.styles";

const ColorThemeToggler = ({ theme, showText = true }) => {
  const { currentColorTheme, toggleColorTheme } = useColorTheme();

  const lightThemeIsActive = currentColorTheme === ColorThemeOptions.LIGHT;
  const darkThemeIsActive = currentColorTheme === ColorThemeOptions.DARK;

  return (
    <ColorThemeTogglerContainer>
      {showText && <Text>{theme.toggler.text}</Text>}
      <Toggler
        IconOn={sunIcon}
        IconOff={moonIcon}
        onToggle={toggleColorTheme}
        toggled={
          lightThemeIsActive ? true : darkThemeIsActive ? false : undefined
        }
      />
    </ColorThemeTogglerContainer>
  );
};

export default withTheme(ColorThemeToggler);
