export const generateRGBA = (RGB, A) => RGB.replace(")", `,${A})`);

export const generatePercentageByAspectRatio = (width, height) =>
  `${(height / width) * 100}%`;

export const incrementPixelValue = (value) =>
  value
    .split("px")
    .filter((i) => i !== "")
    .map((el) => `${Number(el) + 1}px`)
    .join();
