import { useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import useEventListener from "./useEventListener.hook";
import { getCurrentIfRef, disableCursor } from "utils/helpers/global.helpers";

import { CursorTypes } from "redux/cursor/cursor.data";
import { updateCursorType } from "redux/cursor/cursor.actions";
import { cursorTypeSelector } from "redux/cursor/cursor.selectors";

const useCursor = () => {
  const dispatch = useDispatch();

  const cursorType = useSelector((state) => cursorTypeSelector(state));

  const onUpdateCursorType = useCallback(
    (type) => dispatch(updateCursorType(type)),
    [dispatch]
  );

  const cursorIsPointer = cursorType === CursorTypes.POINTER;

  return {
    cursorType,
    cursorIsPointer,
    updateCursorType: onUpdateCursorType,
  };
};

export const useToggleCursorPointer = (target) => {
  let timeoutRef = useRef();
  const { updateCursorType, cursorType } = useCursor();

  const turnOffCursorPointer = () => {
    timeoutRef.current = setTimeout(() => {
      if (cursorType === CursorTypes.DEFAULT) return;

      updateCursorType(CursorTypes.DEFAULT);
    }, 250);
  };

  const turnOnCursorPointer = () => {
    clearTimeout(timeoutRef.current);

    if (cursorType === CursorTypes.POINTER) return;

    updateCursorType(CursorTypes.POINTER);
  };

  useEffect(() => {
    const currentTarget = getCurrentIfRef(target);

    if (!currentTarget) return;

    currentTarget.length > 1
      ? currentTarget.forEach((el) => disableCursor(getCurrentIfRef(el)))
      : disableCursor(currentTarget);
  }, [target]);

  useEventListener(target, "mousemove", turnOnCursorPointer);
  useEventListener(target, "mouseleave", turnOffCursorPointer);

  return {
    turnOnCursorPointer,
    turnOffCursorPointer,
  };
};

export default useCursor;
