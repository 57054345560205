export const GRID_DATA = [
  {
    label: 'I can do this (and more)',
    list: [
      {
        text: 'Web Development',
      },
      {
        text: 'Animation & Interactivity',
      },
      {
        text: 'User Experience & Interface',
      },
      {
        text: 'Web Design',
      },
      {
        text: 'Project Management',
      },
      {
        text: 'Work alone or with your team',
      },
    ],
  },
  {
    label: 'Last seen at',
    list: [
      {
        text: 'Verse',
        url: 'https://www.verse.com',
      },
      {
        text: 'Ready Set Rocket',
        url: 'https://readysetrocket.com',
      },
      {
        text: 'Work & Co',
        url: 'https://work.co',
      },
      {
        text: 'Brooklyn Digital Foundry',
        url: 'https://brooklynfoundry.com/',
      },
    ],
  },
  {
    label: 'Find me on',
    list: [
      {
        text: 'LinkedIn',
        url: 'https://www.linkedin.com/in/clausse/',
      },
      {
        text: 'Github',
        url: 'https://github.com/melissaclausse',
      },
      {
        text: 'Calendly (Let’s chat!)',
        url: 'https://calendly.com/clausse',
      },
      {
        text: 'Instagram (I make ceramics)',
        url: 'https://instagram.com/clausse_',
      },
    ],
  },
];
