import React, { useEffect, useRef, memo } from "react";
import useHiddenClass from "hooks/useHiddenClass.hook";
import gsap from "gsap";

import { PeriodContainer, PeriodIcon } from "./Period.styles";

const Period = ({ selectable = true }) => {
  const hiddenClass = useHiddenClass();
  const periodRef = useRef();

  useEffect(() => {
    gsap.from(periodRef.current, {
      delay: 0.2,
      duration: 0.6,
      scale: 1.4,
      opacity: 0,
      rotate: -90,
      ease: "back.out(2)",
      scrollTrigger: {
        trigger: periodRef.current,
        start: "center bottom",
        toggleActions: "play none none reverse",
      },
    });
  }, []);

  return (
    <PeriodContainer ref={periodRef} className={hiddenClass}>
      <PeriodIcon />
    </PeriodContainer>
  );
};

export default memo(Period);
