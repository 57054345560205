import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import themesReducer from "./themes/themes.reducer";
import cursorReducer from "./cursor/cursor.reducer";

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  themes: themesReducer,
  cursor: cursorReducer,
});

export default persistReducer(persistConfig, rootReducer);
