import React, { useEffect, useRef } from "react";
import useHiddenClass from "hooks/useHiddenClass.hook";
import gsap from "gsap";

import Label from "components/_global/Label/Label.component";

import { HomeGridItemContainer } from "./HomeGridItem.styles";

const HomeGridItem = ({ index, children, label, colSpan }) => {
  const hiddenClass = useHiddenClass();
  const itemRef = useRef();

  useEffect(() => {
    gsap.from(itemRef.current, {
      duration: 0.4,
      delay: `0.${index / 2}`,
      y: `${(index + 1) * 5 + 10}%`,
      opacity: 0,
      ease: "expo.inout",
      scrollTrigger: {
        trigger: itemRef.current,
        start: "top bottom",
        toggleActions: "play none none reverse",
      },
    });
  }, [index]);

  return (
    <HomeGridItemContainer
      ref={itemRef}
      className={hiddenClass}
      colSpan={colSpan}
    >
      <Label text={label} />
      {children}
    </HomeGridItemContainer>
  );
};

export default HomeGridItem;
