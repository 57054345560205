import React, { memo } from "react";

import CustomLink from "components/_global/CustomLink/CustomLink.component";
import UnderlineHover from "components/_global/UnderlineHover/UnderlineHover.component";

import { HeadlineLinkStyles } from "./HeadlineLink.styles";

const HeadlineLink = ({ url, text }) => (
  <UnderlineHover
    customStyles={HeadlineLinkStyles}
    bottomPosition="2%"
    strokeWidth="8%"
  >
    <CustomLink url={url} text={text} />
  </UnderlineHover>
);

export default memo(HeadlineLink);
