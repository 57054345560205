import ColorThemeOptions from "themes/color/colorTheme.options";
import LanguageThemeOptions from "themes/language/languageTheme.options";

export const INITIAL_STATE = {
  color: {
    current: ColorThemeOptions.DARK,
    selectedByUser: false,
  },
  language: {
    current: LanguageThemeOptions.EN,
    selectedByUser: false,
  },
};
