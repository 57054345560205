import styled from "styled-components";
import { mediaQueryW } from "styles/utils/mixins.styles";

export const HomeGridItemContainer = styled.div`
  ${mediaQueryW.min.m`
    grid-column-end: span ${({ colSpan }) => colSpan || 1};
  `}
`;

export const ListContainer = styled.ul``;

export const ListItemContainer = styled.li``;
