import React, { useRef, memo } from "react";
import { useToggleCursorPointer } from "hooks/useCursor.hook";

import {
  TogglerContainer,
  TogglerInnerWrapper,
  TogglerThumb,
  IconOnContainer,
  IconOffContainer,
} from "./Toggler.styles";

const Toggler = ({ onToggle, toggled, IconOn, IconOff }) => {
  const togglerRef = useRef();

  useToggleCursorPointer(togglerRef);

  return (
    <TogglerContainer ref={togglerRef} onClick={onToggle} toggled={toggled}>
      <TogglerInnerWrapper>
        {IconOn && (
          <IconOnContainer toggled={toggled}>
            <IconOn />
          </IconOnContainer>
        )}
        <TogglerThumb toggled={toggled} />
        {IconOff && (
          <IconOffContainer toggled={toggled}>
            <IconOff />
          </IconOffContainer>
        )}
      </TogglerInnerWrapper>
    </TogglerContainer>
  );
};

export default memo(Toggler);
