import { useEffect } from "react";
import { getCurrentIfRef } from "utils/helpers/global.helpers";

const useEventListener = (target, event, handler, ...options) => {
  useEffect(() => {
    const currentTarget = getCurrentIfRef(target);

    if (!currentTarget) return;

    const handleAddListener = (el) => {
      if (!el.addEventListener) return;
      el.addEventListener(event, handler, ...options);
    };

    const handleRemoveListener = (el) => {
      if (!el.removeEventListener) return;
      el.removeEventListener(event, handler, ...options);
    };

    currentTarget.length > 1
      ? currentTarget.forEach((el) => {
          handleAddListener(getCurrentIfRef(el));
        })
      : handleAddListener(currentTarget);

    return () => {
      currentTarget.length > 1
        ? currentTarget.forEach((el) => {
            handleRemoveListener(getCurrentIfRef(el));
          })
        : handleRemoveListener(currentTarget);
    };
  }, [target, event, handler, options]);
};

export default useEventListener;
