import React, { useMemo, useEffect, useRef } from "react";
import { withTheme } from "styled-components";
import gsap from "gsap";
import useCursor from "hooks/useCursor.hook";
import { MorphSVGPlugin } from "gsap/MorphSVGPlugin";
import { CursorTypes } from "redux/cursor/cursor.data";

import { CustomCursorIconContainer } from "./CustomCursorIcon.styles";

gsap.registerPlugin(MorphSVGPlugin);

const CustomCursorIcon = ({ theme, isDown }) => {
  const defaultRef = useRef();
  const pointerRef = useRef();
  const { cursorType } = useCursor();

  const cursorConfig = useMemo(
    () => ({
      [CursorTypes.DEFAULT]: {
        ref: defaultRef,
        strokeWidth: 6,
        rotate: 30,
        x: 45,
        y: -15,
        scale: 1,
      },
      [CursorTypes.POINTER]: {
        ref: pointerRef,
        strokeWidth: 6,
        rotate: 0,
        x: -20,
        y: 0,
        scale: 1.2,
      },
    }),
    []
  );

  useEffect(() => {
    gsap.to(defaultRef.current, {
      duration: 0.2,
      visibility: "visible",
      ease: "expo.inout",
      morphSVG: {
        shape: cursorConfig[cursorType].ref.current,
        type: "rotational",
      },
      strokeWidth: cursorConfig[cursorType].strokeWidth,
      rotate: cursorConfig[cursorType].rotate,
      x: cursorConfig[cursorType].x,
      y: cursorConfig[cursorType].y,
      scale: cursorConfig[cursorType].scale,
    });
  }, [cursorConfig, cursorType, theme]);

  return (
    <CustomCursorIconContainer
      width="120px"
      height="120px"
      viewBox="0 0 120 120"
    >
      <path
        ref={defaultRef}
        d="M-11.6299375,18.3736903 L20.8630955,106.044208 L31.3850207,71.8479507 L64.6432311,105.106161 L75.2190157,94.5303764 L42.0809924,61.392353 L75.9501389,50.9804264 L-11.6299375,18.3736903 Z"
        stroke={theme.baseColor}
        fill="none"
        strokeWidth={5}
        fillRule="nonzero"
        strokeLinecap="round"
        strokeLinejoin="round"
        transform="translate(45, 130) translate(-45, -130)"
      ></path>
      <path
        ref={pointerRef}
        d="M36.6707182,3.5 C34.5206798,3.5 32.5838917,4.3757466 31.1844925,5.79704376 C29.8045451,7.19858482 28.9451736,9.13583197 28.9451736,11.2916667 L28.9451736,74.0972314 L16.7144113,61.6750802 C14.9666342,59.8999536 13.0997229,59.1983229 11.3169221,59.1983229 C9.53589995,59.1983229 7.66894927,59.8989564 5.91902094,61.6722829 C4.19360717,63.4246931 3.5,65.2909152 3.5,67.0811875 C3.5,68.8741773 4.1957145,70.7433478 5.92411555,72.4952615 L40.2952124,106.998786 C45.3639937,110.92325 51.4971938,113.5 58.5928439,113.5 L65.8523296,113.5 C74.1589975,113.5 81.5711214,110.176492 86.910097,104.754847 C92.2302688,99.3522979 95.5,91.8542849 95.5,83.4474583 L95.5,49.9166667 C95.5,47.7616893 94.6401451,45.8247029 93.2601293,44.4230924 C91.859497,43.0005428 89.9214699,42.125 87.7744553,42.125 C85.6244169,42.125 83.6876288,43.0007466 82.2882297,44.4220438 C80.9082822,45.8235848 80.0489107,47.760832 80.0489107,49.9166667 L80.0489107,55 L78.2006202,55 L78.2006202,41.3333333 C78.2006202,39.1774986 77.3412486,37.2402515 75.9613012,35.8387104 C74.561902,34.4174133 72.6251139,33.5416667 70.4750755,33.5416667 C68.3250371,33.5416667 66.388249,34.4174133 64.9888498,35.8387104 C63.6089024,37.2402515 62.7495308,39.1774986 62.7495308,41.3333333 L62.7495308,55 L61.2984415,55 L61.2984415,41.3333333 C61.2984415,39.1774986 60.43907,37.2402515 59.0591225,35.8387104 C57.6597234,34.4174133 55.7229353,33.5416667 53.5728969,33.5416667 C51.4228584,33.5416667 49.4860703,34.4174133 48.0866712,35.8387104 C46.7067238,37.2402515 45.8473522,39.1774986 45.8473522,41.3333333 L45.8473522,55 L44.3962629,55 L44.3962629,11.2916667 C44.3962629,9.13583197 43.5368913,7.19858482 42.1569439,5.79704376 C40.7575448,4.3757466 38.8207567,3.5 36.6707182,3.5 Z"
        fill="none"
        stroke={theme.primaryColor}
        strokeWidth={4}
      ></path>
    </CustomCursorIconContainer>
  );
};

export default withTheme(CustomCursorIcon);
