import { css } from "styled-components";
import { FONT_FAMILY } from "./fonts.styles";

export const globalStyles = css`
  html {
    font-size: 62.5%;
    height: 100%;

    ${({ theme }) => css`
      background-color: ${theme.tertiaryColor};
      ${"" /* background-color: ${theme.secondaryColor}; */}
    `}
  }

  body {
    font-family: ${FONT_FAMILY.SERIF};
    min-height: 100%;

    ${({ theme }) => css`
      background-color: ${theme.tertiaryColor};
      color: ${theme.baseColor};
      transition: background-color 0.15s ease;
    `}
  }

  em,
  i {
    font-style: italic;
  }

  ::selection {
    ${({ theme }) => css`
      background-color: ${theme.selection.backgroundColor};
      color: ${theme.selection.fontColor};
    `}
  }

  .animatedHidden {
    visibility: hidden;
  }
`;
