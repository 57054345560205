import React from "react";
import { GRID_DATA } from "./HomeGrid.data";

import HomeGridItem from "../HomeGridItem/HomeGridItem.component";
import HomeGridList from "../HomeGridList/HomeGridList.component";
import HomeGridContact from "../HomeGridContact/HomeGridContact.component";

import { HomeGridContainer } from "./HomeGrid.styles";

const HomeGrid = () => (
  <HomeGridContainer>
    {GRID_DATA.map((item, i) => (
      <HomeGridItem key={i} index={i} label={item.label}>
        <HomeGridList list={item.list} />
      </HomeGridItem>
    ))}
    <HomeGridItem index={GRID_DATA.length} label="Ready to chat?" colSpan={2}>
      <HomeGridContact />
    </HomeGridItem>
  </HomeGridContainer>
);

export default HomeGrid;
