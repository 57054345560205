import React, { memo } from "react";

import { LabelContainer } from "./Label.styles";

const Label = ({ text, children }) => (
  <LabelContainer>
    {text}
    {children}
  </LabelContainer>
);

export default memo(Label);
