import { css } from "styled-components";
import { BREAKPOINTS } from "./variables.styles";
import { incrementPixelValue } from "./helpers.styles";

const generatemediaQueryBlock = (rule, breakpoint, ...args) => css`
  @media (${rule}: ${breakpoint}) {
    ${css(...args)};
  }
`;

const generateBreakpointsObject = (breakpoints, rule) => ({
  ...Object.keys(breakpoints).reduce((accumulator, breakpointName) => {
    const breakpointValue = breakpoints[breakpointName];
    const shouldIncrement =
      rule.includes("min") && breakpointValue.includes("px");
    const breakpoint = shouldIncrement
      ? incrementPixelValue(breakpointValue)
      : breakpointValue;

    accumulator[breakpointName] = (...args) =>
      generatemediaQueryBlock(rule, breakpoint, ...args);
    return accumulator;
  }, {}),
});

const customBreakpoint = (rule) => ({
  custom: (breakpoint) => (...args) =>
    generatemediaQueryBlock(rule, breakpoint, ...args),
});

const generateMediaQueryObject = (breakpoints, rule) => ({
  ...generateBreakpointsObject(breakpoints, rule),
  ...customBreakpoint(rule),
});

export const mediaQueryW = {
  min: generateMediaQueryObject(BREAKPOINTS.WIDTH, "min-width"),
  max: generateMediaQueryObject(BREAKPOINTS.WIDTH, "max-width"),
};

export const mediaQueryH = {
  min: generateMediaQueryObject(BREAKPOINTS.HEIGHT, "min-height"),
  max: generateMediaQueryObject(BREAKPOINTS.HEIGHT, "max-height"),
};
