import styled from "styled-components";

export const PeriodWrapper = styled.span`
  display: inline-block;
  margin: 0 0.5em;
`;

export const PeriodText = styled.span`
  width: 1px;
  height: 0;
  opacity: 0;
  font-size: 0;
  overflow: hidden;
`;
