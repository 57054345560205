import styled from "styled-components";
import { mediaQueryW } from "styles/utils/mixins.styles";

export const Cursor = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  pointer-events: none;
  overflow: visible;
  transform-origin: top left;
  opacity: 0;
`;

export const CursorInner = styled.div`
  position: relative;
  overflow: visible;
  box-shadow : 0px -0px 10000px transparent;
  transform: translate(-30%, -2%);
  width: 9rem;
  height: 9rem;

  svg {
    width: 100%;
    height: 100%;
  }

  ${mediaQueryW.max.d`
    width: 8rem;
    height: 8rem;
  `}

  ${mediaQueryW.max.m`
    width: 7rem;
    height: 7rem;
  `}

  ${mediaQueryW.max.xs`
    width: 6rem;
    height: 6rem;
  `}
`;

export const CursorTrail = styled.div`
  position: fixed;
  background-color: black;
  width: 30px;
  height: 30px;
  top: 0;
  left: 0;
  z-index: 199;
  pointer-events: none;
  display: none;
`;
