import { INITIAL_STATE } from "./themes.data";
import ThemesTypes from "./themes.types";
import ColorThemeOptions from "themes/color/colorTheme.options";

const themesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ThemesTypes.TOGGLE_COLOR_THEME:
      return {
        color: {
          current:
            state.color.current === ColorThemeOptions.DARK
              ? ColorThemeOptions.LIGHT
              : ColorThemeOptions.DARK,
        },
      };
    default:
      return state;
  }
};

export default themesReducer;
