import React, { useState, memo } from "react";
import { useSpring } from "react-spring";

import { UnderlineHoverContainer } from "./UnderlineHover.styles";

const UnderlineHover = ({
  children,
  strokeWidth = ".125em",
  color,
  hoverColor,
  bottomPosition = 0,
  customStyles,
}) => {
  const [hover, setHover] = useState(false);
  let mouseLeaveTimeout;

  const styleOptions = {
    strokeWidth,
    color,
    hoverColor,
    bottomPosition,
  };

  const animation = useSpring({
    backgroundSize: hover
      ? `100% ${strokeWidth}, 0% ${strokeWidth}`
      : `0% ${strokeWidth}, 100% ${strokeWidth}`,
  });

  const handleMouseEnter = () => {
    clearTimeout(mouseLeaveTimeout);
    setHover(true);
  };

  const handleMouseLeave = () => {
    mouseLeaveTimeout = setTimeout(() => setHover(false), 250);
  };

  return (
    <UnderlineHoverContainer
      style={animation}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      styleOptions={styleOptions}
      customStyles={customStyles}
      className={hover && "isHovered"}
    >
      {children}
    </UnderlineHoverContainer>
  );
};

export default memo(UnderlineHover);
