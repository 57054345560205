import React from "react";
import GlobalStyles from "./styles/GlobalStyles.styles";
import { Switch, Route, Redirect } from "react-router-dom";
import ColorThemeProvider from "./themes/color/ColorTheme.provider";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import DrawSVGPlugin from "gsap/DrawSVGPlugin";
import Physics2DPlugin from "gsap/Physics2DPlugin";

import CustomCursor from "components/_global/CustomCursor/CustomCursor.component";
import Header from "components/header/Header/Header.component";
import Footer from "components/footer/Footer/Footer.component";
import HomePage from "pages/HomePage/HomePage.component.jsx";

import { SiteWrapper } from "./App.styles";

gsap.registerPlugin(ScrollTrigger, DrawSVGPlugin, Physics2DPlugin);

const App = () => {
  return (
    <ColorThemeProvider>
      <GlobalStyles />
      <SiteWrapper>
        <CustomCursor />
        <Header />
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Redirect to="/" />
        </Switch>
        <Footer />
      </SiteWrapper>
    </ColorThemeProvider>
  );
};

export default App;
