import React from "react";
import { withTheme } from "styled-components";
import UnderlineHover from "components/_global/UnderlineHover/UnderlineHover.component";

import { HomeGridContactContainer } from "./HomeGridContact.styles";

const HomeGridContact = ({ theme, index }) => (
  <HomeGridContactContainer>
    If you want to work together, have a project in mind, or just want to chat,{" "}
    <UnderlineHover hoverColor={theme.baseColor}>
      <a href="mailto:hello@clausse.work">send me a note</a>
    </UnderlineHover>
    !
  </HomeGridContactContainer>
);

export default withTheme(HomeGridContact);
