import styled from "styled-components";
import { mediaQueryW } from "styles/utils/mixins.styles";
import { HomeHeroContainer } from "components/home/HomeHero/HomeHero.styles";

export const HomePageContainer = styled.div`
  ${HomeHeroContainer} {
    margin-bottom: 25rem;

    ${mediaQueryW.max.d`
      margin-bottom: 22rem;
    `}

    ${mediaQueryW.max.md`
      margin-bottom: 19rem;
    `}

    ${mediaQueryW.max.m`
      margin-bottom: 16rem;
    `}

    ${mediaQueryW.max.s`
      margin-bottom: 14rem;
    `}

    ${mediaQueryW.max.xs`
      margin-bottom: 12rem;
    `}

    ${mediaQueryW.max.xxs`
      margin-bottom: 10rem;
    `}
  }
`;
