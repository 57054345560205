import React, { useRef, memo } from "react";
import { useHistory } from "react-router-dom";
import { useToggleCursorPointer } from "hooks/useCursor.hook";
import { checkIfUrlIsExternal } from "utils/helpers/global.helpers";

import { InternalLink, ExternalLink } from "./CustomLink.styles";

const CustomLink = ({ url, text, children }) => {
  const history = useHistory();
  const isExternalUrl = url && checkIfUrlIsExternal(url);
  const internalLinkRef = useRef();

  const handleClick = () => history.push(url);

  useToggleCursorPointer(internalLinkRef);

  return isExternalUrl ? (
    <ExternalLink href={url} target="_blank" rel="noopener noreferrer">
      {text}
      {children}
    </ExternalLink>
  ) : (
    <InternalLink onClick={handleClick} ref={internalLinkRef}>
      {text}
      {children}
    </InternalLink>
  );
};

export default memo(CustomLink);
