import { createGlobalStyle } from "styled-components";
import { resetStyles } from "./utils/reset.styles";
import { globalStyles } from "./utils/global.styles";
import { fontFaces } from "./utils/fonts.styles";
import { typographyStyles } from "./utils/typography.styles";

const GlobalStyles = createGlobalStyle`
  ${resetStyles}
  ${fontFaces}
  ${globalStyles}
  ${typographyStyles}
`;

export default GlobalStyles;
