import { css } from "styled-components";
import CanelaLightWoff from "assets/fonts/canela/canela-light.woff";
import CanelaLightWoff2 from "assets/fonts/canela/canela-light.woff2";
import CanelaLightItalicWoff from "assets/fonts/canela/canela-light_italic.woff";
import CanelaLightItalicWoff2 from "assets/fonts/canela/canela-light_italic.woff2";
import ApercuLightWoff from "assets/fonts/apercu/apercu-light.woff";
import ApercuLightWoff2 from "assets/fonts/apercu/apercu-light.woff2";
import ApercuLightItalicWoff from "assets/fonts/apercu/apercu-light_italic.woff";
import ApercuLightItalicWoff2 from "assets/fonts/apercu/apercu-light_italic.woff2";
import ApercuRegularWoff from "assets/fonts/apercu/apercu-regular.woff";
import ApercuRegularWoff2 from "assets/fonts/apercu/apercu-regular.woff2";
import ApercuRegularItalicWoff from "assets/fonts/apercu/apercu-regular_italic.woff";
import ApercuRegularItalicWoff2 from "assets/fonts/apercu/apercu-regular_italic.woff2";
import ApercuBoldWoff from "assets/fonts/apercu/apercu-bold.woff";
import ApercuBoldWoff2 from "assets/fonts/apercu/apercu-bold.woff2";
import ApercuBoldItalicWoff from "assets/fonts/apercu/apercu-bold_italic.woff";
import ApercuBoldItalicWoff2 from "assets/fonts/apercu/apercu-bold_italic.woff2";

export const FONT_FAMILY = {
  SERIF: "Canela",
  SANS_SERIF: "Apercu",
};

export const fontFaceSerif = css`
  @font-face {
    font-family: ${FONT_FAMILY.SERIF};
    src: local("Canela Light"), local("Canela-Light"),
      url(${CanelaLightWoff}) format("woff"),
      url(${CanelaLightWoff2}) format("woff2");
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: ${FONT_FAMILY.SERIF};
    src: local("Canela Light Italic"), local("Canela-LightItalic"),
      url(${CanelaLightItalicWoff}) format("woff"),
      url(${CanelaLightItalicWoff2}) format("woff2");
    font-weight: 300;
    font-style: italic;
  }
`;

export const fontFaceSansSerif = css`
  @font-face {
    font-family: ${FONT_FAMILY.SANS_SERIF};
    src: url(${ApercuLightWoff}) format("woff"),
      url(${ApercuLightWoff2}) format("woff2");
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: ${FONT_FAMILY.SANS_SERIF};
    src: url(${ApercuLightItalicWoff}) format("woff"),
      url(${ApercuLightItalicWoff2}) format("woff2");
    font-weight: 300;
    font-style: italic;
  }

  @font-face {
    font-family: ${FONT_FAMILY.SANS_SERIF};
    src: url(${ApercuRegularWoff}) format("woff"),
      url(${ApercuRegularWoff2}) format("woff2");
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: ${FONT_FAMILY.SANS_SERIF};
    src: url(${ApercuRegularItalicWoff}) format("woff"),
      url(${ApercuRegularItalicWoff2}) format("woff2");
    font-weight: 500;
    font-style: italic;
  }

  @font-face {
    font-family: ${FONT_FAMILY.SANS_SERIF};
    src: url(${ApercuBoldWoff}) format("woff"),
      url(${ApercuBoldWoff2}) format("woff2");
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: ${FONT_FAMILY.SANS_SERIF};
    src: url(${ApercuBoldItalicWoff}) format("woff"),
      url(${ApercuBoldItalicWoff2}) format("woff2");
    font-weight: bold;
    font-style: italic;
  }
`;

export const fontFaces = css`
  ${fontFaceSerif}
  ${fontFaceSansSerif}
`;
