import React, { Fragment, memo } from "react";

import Period from "components/_global/Period/Period.component";

import { PeriodWrapper, PeriodText } from "./TextWithCustomPeriods.styles";

const TextWithCustomPeriods = ({ text }) => {
  if (!text) return <Period />;

  const parts = text.split(/[.\\*]/);

  const formattedHTML = (text) => (
    <span dangerouslySetInnerHTML={{ __html: text }} />
  );

  if (parts.length === 1) return formattedHTML(parts);

  return (
    <>
      {parts.map((part, i) => (
        <Fragment key={i}>
          {i > 0 && (
            <PeriodWrapper>
              <PeriodText>. </PeriodText>
              <Period />
            </PeriodWrapper>
          )}
          {part !== "" && formattedHTML(part)}
        </Fragment>
      ))}
    </>
  );
};

export default memo(TextWithCustomPeriods);
