import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { currentColorThemeSelector } from "redux/themes/themes.selectors";
import { toggleColorTheme } from "redux/themes/themes.actions";

export const useColorTheme = () => {
  const dispatch = useDispatch();

  const currentColorTheme = useSelector((state) =>
    currentColorThemeSelector(state)
  );

  const onToggleColorTheme = useCallback(() => {
    dispatch(toggleColorTheme());
  }, [dispatch]);

  return {
    currentColorTheme,
    toggleColorTheme: onToggleColorTheme,
  };
};

export const useLanguageTheme = () => {};
