import React from "react";
import { ThemeProvider } from "styled-components";
import ColorThemeMap from "./colorTheme.map";
import { useColorTheme } from "hooks/useThemes.hook";

const ColorThemeProvider = ({ children }) => {
  const { currentColorTheme } = useColorTheme();

  return (
    <ThemeProvider theme={ColorThemeMap[currentColorTheme]}>
      {children}
    </ThemeProvider>
  );
};

export default ColorThemeProvider;
