export const BREAKPOINTS = {
  WIDTH: {
    xxs: "480px",
    xs: "620px",
    s: "768px",
    m: "1024px",
    md: "1280px",
    d: "1440px",
    l: "1660px",
  },
  HEIGHT: {
    xxs: "320px",
    xs: "380px",
    s: "520px",
    m: "580px",
    md: "600px",
    d: "620px",
    l: "680px",
  },
};

export const COLORS = {
  blue: {
    standard: "#003DFF",
    medium: "#0024D2",
    rgb: {},
  },
  yellow: {
    standard: "#e0f53c",
    rgb: {
      standard: "rgb(224, 245, 60)",
    },
  },
  red: {
    standard: "#FF5400",
    rgb: {
      standard: "rgb(255, 84, 0)",
    },
  },
  greyscale: {
    darkest: "#000",
    mediumLightest: "#DCDDFF",
    light: "#F5F6FA",
    lighter: "#FAFBFF",
    lightest: "#fff",
    rgb: {
      darkest: "rgb(0,0,0)",
      light: "rgb(245,246,250)",
      lighter: "rgb(250,251,255)",
      lightest: "rgb(255,255,255)",
    },
  },
};
