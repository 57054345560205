import { createSelector } from "reselect";

const themesSelector = (state) => state.themes;

const colorThemeSelector = createSelector(
  themesSelector,
  (themes) => themes.color
);

export const currentColorThemeSelector = createSelector(
  colorThemeSelector,
  (colorTheme) => colorTheme.current
);
