import { css } from "styled-components";
import { FONT_FAMILY } from "./fonts.styles";
import { mediaQueryW } from "./mixins.styles";

export const Heading1Styles = css`
  font-family: ${FONT_FAMILY.SERIF};
  font-size: 8.5rem;
  line-height: 1.2;
  letter-spacing: 0.013em;

  ${mediaQueryW.max.d`
    font-size: 7.5rem;
  `}

  ${mediaQueryW.max.md`
    font-size: 6.75rem;
  `}

  ${mediaQueryW.max.m`
    font-size: 6.25rem;
  `}

  ${mediaQueryW.max.s`
    font-size: 5.25rem;
  `}

  ${mediaQueryW.max.xs`
    font-size: 4.5rem;
  `}

  ${mediaQueryW.max.xxs`
    font-size: 4rem;
  `}
`;

export const BodyStyles = css`
  font-family: ${FONT_FAMILY.SANS_SERIF};
  font-weight: 300;
  font-size: 2.6rem;
  line-height: 1.53;
  letter-spacing: -0.0115em;

  ${mediaQueryW.max.d`
    font-size: 2.5rem;
  `}

  ${mediaQueryW.max.md`
    font-size: 2.35rem;
  `}

  ${mediaQueryW.max.m`
    font-size: 2.2rem;
  `}

  ${mediaQueryW.max.s`
    font-size: 2rem;
  `}

  ${mediaQueryW.max.xs`
    font-size: 1.8rem;
  `}

  ${mediaQueryW.max.xxs`
    font-size: 1.8rem;
  `}
`;

export const LabelStyles = css`
  font-size: 1.6rem;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  ${mediaQueryW.max.d`
    font-size: 1.4rem;
  `}

  ${mediaQueryW.max.m`
    font-size: 1.3rem;
  `}

  ${mediaQueryW.max.s`
    font-size: 1.2rem;
  `}

  ${mediaQueryW.max.xs`
    font-size: 1.1rem;
  `}

  ${mediaQueryW.max.xxs`
    font-size: 1rem;
  `}
`;

export const SmallStyles = css`
  font-size: 1.9rem;
  letter-spacing: -0.005em;

  ${mediaQueryW.max.d`
    font-size: 1.8rem;
  `}

  ${mediaQueryW.max.m`
    font-size: 1.65rem;
  `}

  ${mediaQueryW.max.s`
    font-size: 1.5rem;
  `}

  ${mediaQueryW.max.xs`
    font-size: 1.35rem;
  `}
`;

export const typographyStyles = css`
  body {
    ${BodyStyles}
  }

  h1 {
    ${Heading1Styles}
  }

  h6 {
    ${LabelStyles}
  }

  p {
    ${BodyStyles}
  }

  small {
    ${SmallStyles}
  }
`;
