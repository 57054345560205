import styled from "styled-components";
import { mediaQueryW } from "styles/utils/mixins.styles";

export const SiteWrapper = styled.div`
  padding: 7.5rem 11rem;

  ${mediaQueryW.max.d`
    padding: 7rem 10rem;
  `}

  ${mediaQueryW.max.md`
    padding: 6.5rem 8rem;
  `}

  ${mediaQueryW.max.m`
    padding: 6rem 7rem;
  `}

  ${mediaQueryW.max.s`
    padding: 5rem 5.5rem;
  `}

  ${mediaQueryW.max.xs`
    padding: 4rem 4.5rem;
  `}

  ${mediaQueryW.max.xxs`
    padding: 3rem 3.5rem;
  `}
`;
