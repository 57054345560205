import React from "react";
import { HERO_DATA } from "./HomeHero.data";

import Headline from "components/_global/Headline/Headline.component";

import { HomeHeroContainer } from "./HomeHero.styles";

const HomeHero = () => (
  <HomeHeroContainer>
    {HERO_DATA.map((headlineData, index) => (
      <Headline key={index} blocks={headlineData} />
    ))}
  </HomeHeroContainer>
);

export default HomeHero;
