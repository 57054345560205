import React from "react";

import Period from "components/_global/Period/Period.component";
import ColorThemeToggler from "../ColorThemeToggler/ColorThemeToggler.component";

import { FooterContainer, Copyright } from "./Footer.styles";

const Footer = ({ theme }) => (
  <FooterContainer>
    <Copyright>
      <Period />
      &copy; {new Date().getFullYear()} – Mélissa Clausse
    </Copyright>
    <ColorThemeToggler />
  </FooterContainer>
);

export default Footer;
