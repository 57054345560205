import styled, { css } from "styled-components";
import { mediaQueryW } from "styles/utils/mixins.styles";

export const TogglerContainer = styled.div`
  display: inline-flex;
  width: 7.2rem;
  height: 3.6rem;
  border: 2px solid ${({ theme }) => theme.baseColor};
  border-radius: 1.8rem;
  padding: 0.3rem;

  ${mediaQueryW.max.m`
    width: 6.3rem;
    height: 3.2rem;
    padding: .3rem;
  `}

  ${mediaQueryW.max.xs`
    width: 5.4rem;
    height: 2.8rem;
    padding: .2rem;
    border-width: 1px;
  `}
`;
export const TogglerInnerWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const TogglerThumb = styled.div`
  position: absolute;
  left: 0;
  top: 50%;
  width: 2.6rem;
  height: 2.6rem;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.secondaryColor};
  transition: all 0.25s ease;
  transition-property: transform, left;
  z-index: 2;

  ${mediaQueryW.max.m`
    width: 2.3rem;
    height: 2.3rem;
  `}

  ${mediaQueryW.max.xs`
    width: 2rem;
    height: 2rem;
  `}

  ${({ toggled }) => css`
    left: ${toggled ? "100%" : 0};
    transform: translate(${toggled ? "-100%" : 0}, -50%);
  `}
`;

const IconStyles = css`
  position: absolute;
  width: 1.8rem;
  height: 1.8rem;
  top: 50%;
  transform: translateY(-50%) scale(1);
  opacity: 1;
  line-height: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  transition: all 0.25s ease;
  transition-property: opacity, transform;

  ${mediaQueryW.max.m`
    width: 1.6rem;
    height: 1.6rem;
  `}

  ${mediaQueryW.max.xs`
    width: 1.4rem;
    height: 1.4rem;
  `}

  & > * {
    width: 100%;
    max-width: 100%;
  }

  svg {
    height: 100%;
    width: 100%;

    ${mediaQueryW.max.xs`
      path {
        stroke-width: 1.5;
      }
    `}
  }
`;

const HiddenIconStyles = css`
  opacity: 0;
  transform: translateY(-50%) scale(0);
`;

export const IconOnContainer = styled.div`
  ${IconStyles}
  left: .5rem;

  ${mediaQueryW.max.m`
    left: .4rem;
  `}

  ${mediaQueryW.max.xs`
    left: .3rem;
  `}

  ${({ toggled }) => !toggled && HiddenIconStyles}
`;

export const IconOffContainer = styled.div`
  ${IconStyles}
  right: .5rem;

  ${mediaQueryW.max.m`
    right: .4rem;
  `}

  ${mediaQueryW.max.xs`
    right: .3rem;
  `}

  ${({ toggled }) => toggled && HiddenIconStyles}
`;
