import React from "react";
import { withTheme } from "styled-components";

import UnderlineHover from "components/_global/UnderlineHover/UnderlineHover.component";
import CustomLink from "components/_global/CustomLink/CustomLink.component";
import AnimatedLogo from "../AnimatedLogo/AnimatedLogo.component";

import { HeaderContainer, Logo, LogoWrapper } from "./Header.styles";

const Header = ({ theme }) => (
  <HeaderContainer>
    <Logo to="/">
      <LogoWrapper>
        <AnimatedLogo />
      </LogoWrapper>
    </Logo>
    <UnderlineHover color="transparent" hoverColor={theme.baseColor}>
      <CustomLink url="mailto:hello@clausse.work">say hello</CustomLink>
    </UnderlineHover>
  </HeaderContainer>
);

export default withTheme(Header);
