import { useState, useCallback, useEffect } from "react";

const useHiddenClass = () => {
  const [hiddenClass, setHiddenClass] = useState("animatedHidden");

  const unHide = useCallback(() => setHiddenClass(null), []);

  useEffect(() => unHide(), [unHide]);

  return hiddenClass;
};

export default useHiddenClass;
