import styled from "styled-components";
import { Link } from "react-router-dom";
import { mediaQueryW, mediaQueryH } from "styles/utils/mixins.styles";
import { generatePercentageByAspectRatio } from "styles/utils/helpers.styles";

export const HeaderContainer = styled.header`
  margin-bottom: 25rem;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  ${mediaQueryW.min.d`
    ${mediaQueryH.max.d`
      margin-bottom: 18rem;
    `}
  `}

  ${mediaQueryW.max.d`
    margin-bottom: 23rem;

    ${mediaQueryH.max.d`
      margin-bottom: 16rem;
    `}
  `}

  ${mediaQueryW.max.md`
    margin-bottom: 21rem;

    ${mediaQueryH.max.md`
      margin-bottom: 14rem;
    `}
  `}

  ${mediaQueryW.max.m`
    margin-bottom: 19rem;

    ${mediaQueryH.max.m`
      margin-bottom: 12rem;
    `}
  `}

  ${mediaQueryW.max.s`
    margin-bottom: 16.5rem;

    ${mediaQueryH.max.s`
      margin-bottom: 10rem;
    `}
  `}

  ${mediaQueryW.max.xs`
    margin-bottom: 13rem;

    ${mediaQueryH.max.xs`
      margin-bottom: 8rem;
    `}
  `}

  ${mediaQueryW.max.xxs`
    margin-bottom: 10rem;

    ${mediaQueryH.max.xxs`
      margin-bottom: 6rem;
    `}
  `}
`;

export const LogoWrapper = styled.span`
  display: block;
  position: relative;
  width: 100%;
  height: 0;
  padding-top: ${generatePercentageByAspectRatio(11, 9)};

  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export const Logo = styled(Link)`
  display: block;
  width: 12.5rem;

  ${mediaQueryW.max.d`
    width: 11.5rem;
  `}

  ${mediaQueryW.max.md`
    width: 10.5rem;
  `}

  ${mediaQueryW.max.m`
    width: 9.5rem;
  `}

  ${mediaQueryW.max.s`
    width: 8.5rem;
  `}

  ${mediaQueryW.max.xs`
    width: 7.5rem;
  `}

  ${mediaQueryW.max.xxs`
    width: 6.5rem;
  `}
`;
