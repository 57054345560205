import React, { useRef, useEffect } from "react";
import useHiddenClass from "hooks/useHiddenClass.hook";
import { withTheme } from "styled-components";
import gsap from "gsap";

import { AnimatedLogoContainer } from "./AnimatedLogo.styles";

const AnimatedLogo = ({ theme }) => {
  const svgRef = useRef();
  const circleRef = useRef();
  const circleRef2 = useRef();
  const mcRef = useRef();
  const mcRef2 = useRef();
  const tl = useRef();
  const hiddenClass = useHiddenClass();

  useEffect(() => {
    tl.current = gsap
      .timeline({
        delay: 0.1,
        paused: true,
        scrollTrigger: { trigger: svgRef.current },
      })
      .from([circleRef.current], {
        duration: 0.25,
        scale: 0,
        transformOrigin: "center center",
        ease: "ease.out",
      })
      .fromTo(
        [mcRef.current, mcRef2.current],
        0.9,
        { drawSVG: "100% 100%", ease: "expo.out" },
        { drawSVG: "100%" },
        "-=.1"
      );
  }, []);

  return (
    <AnimatedLogoContainer
      className={hiddenClass}
      width="110"
      height="92"
      viewBox="0 0 110 92"
      ref={svgRef}
    >
      <defs>
        <path
          id="circle"
          d="M66.168 81.494C94.752 77.258 107 63.057 107 40.654 107 18.254 101.509 0 71.89 0 42.27 0 22 17.838 22 40.24s15.585 45.49 44.168 41.254z"
        />
        <path
          id="mc"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M57.02 89.576c15.758-11.482 15.96-29.755 11.414-34.82-4.546-5.064-12.571-4.93-14.3 0-2.38 6.785 12.508 7.074 12.504 0-.011-20.32-25.73-27.423-41.032-19.725C9.625 43.071 6.58 58.222 7.449 65.758c2.738 23.757 23.772 28.411 36.225 21.023 9.062-5.376 16.15-15.887 11.808-19.535-4.925-4.14-12.424 4.526-17.74 9.086-.598.513 4.697-3.845 7.75-7.96.63-.847 2.687-5.169.822-7.253-3.308-3.694-7.37.647-11.383 3.687-2.318 1.757-9.362 6.37-7.168 4.824 4.755-3.35 9.729-6.157 9.642-10.592-.067-3.442-3.451-6.125-9.887-1.98-2.7 1.74-8.75 7.48-16.662 4.57C.055 57.015-1.604 46.36 1.21 39.151"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          id="mc"
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke={theme.logo.line.regularColor}
          ref={mcRef}
          strokeWidth={3.2}
          d="M57.02 89.576c15.758-11.482 15.96-29.755 11.414-34.82-4.546-5.064-12.571-4.93-14.3 0-2.38 6.785 12.508 7.074 12.504 0-.011-20.32-25.73-27.423-41.032-19.725C9.625 43.071 6.58 58.222 7.449 65.758c2.738 23.757 23.772 28.411 36.225 21.023 9.062-5.376 16.15-15.887 11.808-19.535-4.925-4.14-12.424 4.526-17.74 9.086-.598.513 4.697-3.845 7.75-7.96.63-.847 2.687-5.169.822-7.253-3.308-3.694-7.37.647-11.383 3.687-2.318 1.757-9.362 6.37-7.168 4.824 4.755-3.35 9.729-6.157 9.642-10.592-.067-3.442-3.451-6.125-9.887-1.98-2.7 1.74-8.75 7.48-16.662 4.57C.055 57.015-1.604 46.36 1.21 39.151"
        />
        <mask id="mask" fill="#fff">
          <use xlinkHref="#circle" ref={circleRef2} />
        </mask>
        <use
          fill={theme.logo.circle.color}
          xlinkHref="#circle"
          ref={circleRef}
        />
        <path
          id="mc"
          strokeLinecap="round"
          strokeLinejoin="round"
          mask="url(#mask)"
          stroke={theme.logo.line.maskedColor}
          ref={mcRef2}
          strokeWidth={3.2}
          d="M57.02 89.576c15.758-11.482 15.96-29.755 11.414-34.82-4.546-5.064-12.571-4.93-14.3 0-2.38 6.785 12.508 7.074 12.504 0-.011-20.32-25.73-27.423-41.032-19.725C9.625 43.071 6.58 58.222 7.449 65.758c2.738 23.757 23.772 28.411 36.225 21.023 9.062-5.376 16.15-15.887 11.808-19.535-4.925-4.14-12.424 4.526-17.74 9.086-.598.513 4.697-3.845 7.75-7.96.63-.847 2.687-5.169.822-7.253-3.308-3.694-7.37.647-11.383 3.687-2.318 1.757-9.362 6.37-7.168 4.824 4.755-3.35 9.729-6.157 9.642-10.592-.067-3.442-3.451-6.125-9.887-1.98-2.7 1.74-8.75 7.48-16.662 4.57C.055 57.015-1.604 46.36 1.21 39.151"
        />
      </g>
    </AnimatedLogoContainer>
  );
};

export default withTheme(AnimatedLogo);
