import { COLORS } from "styles/utils/variables.styles";
import { generateRGBA } from "styles/utils/helpers.styles";

export const LightTheme = {
  baseColor: COLORS.greyscale.darkest,
  primaryColor: COLORS.blue.standard,
  secondaryColor: COLORS.yellow.standard,
  tertiaryColor: COLORS.greyscale.light,
  quartenaryColor: COLORS.red.standard,
  selection: {
    backgroundColor: generateRGBA(COLORS.yellow.rgb.standard, 0.99),
    fontColor: COLORS.blue.standard,
  },
  logo: {
    line: {
      regularColor: COLORS.blue.standard,
      maskedColor: COLORS.greyscale.darkest,
    },
    circle: {
      color: COLORS.yellow.standard,
    },
  },
  toggler: {
    text: "Turn off the lights",
  },
};

export const DarkTheme = {
  baseColor: COLORS.greyscale.lightest,
  primaryColor: COLORS.red.standard,
  secondaryColor: COLORS.red.standard,
  tertiaryColor: COLORS.blue.standard,
  quartenaryColor: COLORS.yellow.standard,
  selection: {
    backgroundColor: generateRGBA(COLORS.red.rgb.standard, 0.99),
    fontColor: COLORS.greyscale.lightest,
  },
  logo: {
    line: {
      regularColor: COLORS.greyscale.mediumLightest,
      maskedColor: COLORS.greyscale.lightest,
    },
    circle: {
      color: COLORS.red.standard,
    },
  },
  toggler: {
    text: "Turn on the lights",
  },
};
