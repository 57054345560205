import styled from "styled-components";
import { mediaQueryW } from "styles/utils/mixins.styles";

export const HomeGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 7%;
  grid-row-gap: 13rem;

  ${mediaQueryW.max.d`
    grid-row-gap: 11rem;
  `}

  ${mediaQueryW.max.md`
    grid-row-gap: 9.5rem;
  `}

  ${mediaQueryW.max.m`
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 8.5rem;
  `}

  ${mediaQueryW.max.s`
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 7rem;
  `}

  ${mediaQueryW.max.xs`
    grid-template-columns: 1fr;
    grid-row-gap: 5.5rem;
  `}
`;
