import styled from "styled-components";
import { ReactComponent as PeriodSVG } from "assets/icons/period.svg";
import { mediaQueryW } from "styles/utils/mixins.styles";

export const PeriodContainer = styled.span`
  display: inline-block;
  line-height: 0;
`;

export const PeriodIcon = styled(PeriodSVG)`
  fill: ${({ theme }) => theme.primaryColor};
  width: 2.9rem;
  height: 2.9rem;

  ${mediaQueryW.max.d`
    width: 2.6rem;
    height: 2.6rem;
  `}

  ${mediaQueryW.max.m`
    width: 2.4rem;
    height: 2.4rem;
  `}

  ${mediaQueryW.max.s`
    width: 2rem;
    height: 2rem;
  `}

  ${mediaQueryW.max.xs`
    width: 1.7rem;
    height: 1.7rem;
  `}
`;
